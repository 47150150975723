.topSpace{
    margin: 20px;
    margin-top: 80px;
    height: 70vh;
    background-color: white;
}
.tableSection{
    margin-left: 20px;


}